import React, { useEffect, useState, useMemo } from "react";
import InnerBanner from "./InnerBanner.js";
import "./Styles/ContactUs.css";
import axios from "axios";
import { baseURL } from "../services/Aut.post.js"; // Ensure baseURL is correctly defined

function MyOrders() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [tokens, setTokens] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noTransactionsMessage, setNoTransactionsMessage] = useState("");

  const ordersPerPage = 5;
  const accessTokenForReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenForReferal ? accessTokenForReferal.access : "";

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${btoken}`,
    }),
    [btoken]
  );

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${baseURL}/tockens_api/product_orders_list/`,
          {
            method: "GET",
            headers: headers,
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (Array.isArray(data)) {
          const sortedOrders = data.sort(
            (a, b) => new Date(b.add_date_time) - new Date(a.add_date_time)
          );
          setOrders(sortedOrders);
          setFilteredOrders(sortedOrders);
        } else {
          const sortedOrders = (data.orders || []).sort(
            (a, b) => new Date(b.add_date_time) - new Date(a.add_date_time)
          );
          setOrders(sortedOrders);
          setFilteredOrders(sortedOrders);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [headers]);

  useEffect(() => {
    const fetchHomeDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/userapi/gethomedetails/`, {
          headers: headers,
        });

        const { tockenslist } = response.data;
        setTokens(tockenslist);
      } catch (error) {
        console.error("Error fetching token details", error);
      }
    };

    fetchHomeDetails();
  }, [headers]);

  const onClickOrderSearch = () => {
    if (!startDate || !endDate) {
      setErrorMessage("*Both start and end dates are required.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    if (end < start) {
      setErrorMessage("*End date cannot be earlier than start date.");
      return;
    }
    setErrorMessage("");

    const formattedStartDate = start.toISOString().split("T")[0];
    const formattedEndDate = end.toISOString().split("T")[0];

    axios
      .get(
        `${baseURL}/tockens_api/product_orders_list/${formattedStartDate}/${formattedEndDate}/`,
        { headers: headers }
      )
      .then((response) => {
        const transactions = response.data.orders;
        if (transactions && transactions.length > 0) {
          const sortedOrders = transactions.sort(
            (a, b) => new Date(b.add_date_time) - new Date(a.add_date_time)
          );
          setOrders(sortedOrders);
          setFilteredOrders(sortedOrders);
          setNoTransactionsMessage("");
        } else {
          setOrders([]);
          setFilteredOrders([]);
          setNoTransactionsMessage(
            `No transactions found between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`
          );
        }
      })
      .catch((error) => {
        setOrders([]);
        setFilteredOrders([]);
        setNoTransactionsMessage(
          `No transactions are available between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`
        );
        console.error("Error fetching data:", error);
      });
  };

  const handleClearOrders = () => {
    setStartDate("");
    setEndDate("");
    setErrorMessage("");
    setNoTransactionsMessage("");

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/tockens_api/product_orders_list/`,
          { headers: headers }
        );

        const sortedOrders = response.data.orders.sort(
          (a, b) => new Date(b.add_date_time) - new Date(a.add_date_time)
        );
        setOrders(sortedOrders);
        setFilteredOrders(sortedOrders);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setError("Error fetching data. Please try again.");
      }
    };

    fetchData();
  };

  const currentData = filteredOrders.length > 0 ? filteredOrders : orders;
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = currentData.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(currentData.length / ordersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div>
      <InnerBanner />
      <div className="Myaccount-bg-1 Contact-wrap">
        <div className="wrapper">
          <div className="wrapper-sec-1">
            <h1>Shopping Orders</h1>
            <h2 className="tbalnce">
              Balance Tockens: <span className="tockens-box">{tokens}</span>
            </h2>
          </div>
          <div className="search-i-main">

            <div className="search-i-1">
              <h5>Start Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="search-i-1">
              <h5>End Date</h5>
              <input
                type="date"
                className="search-input-1"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            {/* <div className="search-i-1">
              <button className="button-search" onClick={onClickOrderSearch}>
                Search
              </button>
            </div> */}
            <div className="search-i-1">
              <div className="search-btns">
                <button className="button-search" onClick={onClickOrderSearch}>
                  Search
                </button>
                <button className="button-clear" onClick={handleClearOrders}>
                  Clear
                </button>
              </div>
            </div>

          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {startDate && endDate && !errorMessage && (
            <div>
              <p>
                Showing orders from{" "}
                <strong>{new Date(startDate).toLocaleDateString()}</strong> to{" "}
                <strong>{new Date(endDate).toLocaleDateString()}</strong>
              </p>
            </div>
          )}

          {loading ? (
            <p className="Loading">Loading...</p>
          ) : currentOrders.length > 0 ? (
            currentOrders.map((order , index) => (
              <div className="your-order-main" key={order.product_id || index}>
                <div className="your-order-1-main">
                  <div className="your-order-1-1">
                    Order Id &nbsp;:&nbsp;{order.product_id}
                  </div>
                  <div className="your-order-1-1">
                    Order Date &nbsp;:&nbsp;
                    {new Date(order.add_date_time).toLocaleDateString("en-IN")}
                  </div>
                  <div className="your-order-1-1">
                    Paid Tockens&nbsp;:&nbsp;{order.used_tockens}
                  </div>
                </div>
                <div className="your-order-2-1-main">
                  <div className="your-order-2-1">
                    <img
                      src={`${baseURL}/media/${order.product_image}`}
                      alt={order.product_name}
                    />
                  </div>
                  <div className="your-order-2-2">
                    <h4>{order.product_name}</h4>
                    <p>
                      Email: {order.email}
                      <br />
                      Phone: {order.phone_number}
                    </p>
                  </div>
                  <div className="your-order-2-3">
                    <h5>Shipping Address</h5>
                    <p>
                      {order.full_name},<br />
                      {order.present_address}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-orders">
              {noTransactionsMessage || "No orders found."}
            </div>
          )}

          {/* Pagination Controls */}
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrders;
