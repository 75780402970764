import React from "react";
import Slider from "react-slick";
import "./Styles/TopCategories.css";
import survey from "./images/survey.png";
import Shoppings from "./images/Shoppings.svg";
import Deals from "./images/Deals.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function TopCategories() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-home-1">
        <Slider {...settings}>
          <div>
            <div className="slider-img-2">
              <Link to="/Survey-Categories">
                <img src={survey} alt="PostImg1" />
                <h4>Surveys</h4>
              </Link>
            </div>
          </div>
          <div>
            <div className="slider-img-2">
              <Link to="/Shopping-Categories">
                <img src={Shoppings} alt="PostImg1" />
                <h4>Shoppings</h4>
              </Link>
            </div>
          </div>
          <div>
            <div className="slider-img-2">
              <Link to="/DealsCategories">
                <img src={Deals} alt="PostImg1" />
                <h4>Deals</h4>
              </Link>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default TopCategories;
