import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import { Link, useParams , useNavigate } from "react-router-dom";
import { getSurveyslist } from "../services/Aut.post.js";
import "./SurveyDetails.jsx";

function Surveylist({i}) {
  const [allDeals, setAllDeals] = useState([]);
  const { categoryName, categoryId } = useParams();  // Get dynamic params
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Adjust API call to use categoryId
        const data = await getSurveyslist(categoryId);  // Assuming the API accepts categoryId as a parameter
        console.log("DATA:", data); // Log the data
        
        if (data && data.surveys) {
          setAllDeals(data.surveys);  // Update state with surveys
        } else {
          console.error("Invalid data format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();  // Fetch data when component mounts

  }, [categoryId]);  // Re-fetch when categoryId changes


  return (
    <div className="top-space-1">
      <div className="extra-offer-wrap">
        <h1>Survey's for {categoryName}</h1>  {/* Display category name dynamically */}
        
        <div className="extra-offer-flex">
          {allDeals.length > 0 ? (
            allDeals.map((value, index) => (
              <div className="extra-offer-1" key={index}>
                <div>{value.title}</div>
                <h6>
                  <Link
                    to="/survey-advertisement"
                    state={{
                      categoryName: categoryName,
                      surveyId: value._id,
                      collectionName: value.collection_name,
                    }}
                    // className="btn-2" // Optional: Add styling class
                  >
                    Open Survey
                  </Link>
                </h6>
              </div>
            ))
          ) : (
            <div>
              <h4 style={{ color: "#000" }}>Loading...</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Surveylist;
