import React, { useState, useEffect } from "react";
import InnerBanner from "./InnerBanner.js";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/DashboardSlider.css";
import { baseURL } from "../services/Aut.post.js";
import { useNavigate, useLocation } from "react-router-dom";

function Surveyadvertisement() {
  const [gameval, setgameval] = useState();
  const [sponsoredData, setSponsoredData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  // Destructure data passed from Surveylist
  const { uniqueId, categoryName, surveyId, collectionName } = location.state || {};

  useEffect(() => {
    // Parse the URL and get the value of the 'game' parameter
    const urlParams = new URLSearchParams(window.location.search);
    const gameValue = urlParams.get("contest");
    setgameval(gameValue);

    const formData = {
      contestid: gameValue,
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/userapi/contestsponser/`,
          formData
        );

        // Update localStorage and state
        localStorage.setItem("contest_name", JSON.stringify(response.data.spoContestName));
        localStorage.setItem("sponcer_name", JSON.stringify(response.data.sponsorby));
        localStorage.setItem("game_demo_link", JSON.stringify(response.data.gamedemolink));
        localStorage.setItem("winningtype", JSON.stringify(response.data.winningtype));
        localStorage.setItem("prize", JSON.stringify(response.data.prize));
        localStorage.setItem("AnnouncementDate", JSON.stringify(response.data.AnnouncementDate));
        localStorage.setItem("No_of_winners", JSON.stringify(response.data.No_of_winners));
        localStorage.setItem("stageno", JSON.stringify(response.data.stageno));
        localStorage.setItem("moves", JSON.stringify(response.data.moves));
        localStorage.setItem("time", JSON.stringify(response.data.time));

        setSponsoredData(response.data);
      } catch (error) {
        console.error("Error fetching contests:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    // Navigate back to the survey list page
    navigate(`/Survey-Categories/Survey-${categoryName}-list/${surveyId}/${collectionName}`);
  };

  return (
    <div>
      <InnerBanner />
      <div className="DashboardSlider-bg-1">
        <div className="wrapper paragraph-1">
          <h6>Sponsored by {sponsoredData.sponsorby}</h6>
          <div className="DashboardSlider">
            <a href={sponsoredData.sponsorlink} target="_blank" rel="noopener noreferrer">
              <img
                src={`${baseURL}/media/${sponsoredData.sponsorimage}`}
                alt="contest"
              />
            </a>
            <div className="text-center pt-3 pb-5">
              <button onClick={handleClick} className="btn-2">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Surveyadvertisement;
