import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import ModalContent from "../ModalContent";
import Logo from "../images/logo.png";
import newLogo from "../images/Tockens-Logo.png"
import "./Header.css";
import {  ssoURL, ssoRedirectUrl, getAllResultDetails } from "../../services/Aut.post.js";

function Header({ isAuthenticated, handleLogout }) {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/home");
    setShowNavbar(!showNavbar);
    handleLogout();
  };

  const [showModal, setShowModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const navbarRef = useRef(null);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setShowNavbar(false);
    }
  };

  const handleLoginRedirect = () => {
  //API for site token vefication
  const fetchData = async () => {
    try {
      const data = await getAllResultDetails("getsitesettings","TOCKENSKEY");
      if(data.setting_value){
        // window.location.href = `${ssoRedirectUrl}/BoxPizzaGame`
         window.location.href = `${ssoRedirectUrl}/identifier?next=${ssoURL}/identity&redirectto=${window.location.href}&stocken=${data.setting_value}`;
      // alert( window.location.href)
        }else{
        console.error("Some Error Found:");
      }

      
      // if (data && data.surveys) {
      //   setAllDeals(data.surveys);
      // } else {
      //   console.error("Invalid data format:", data);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchData();  }

  useEffect(() => {
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="header">
        <div class="wrapper">
          <div class="nav-wrapper">
            <div class="nav-1">
              <NavLink to="/">
                <img src={newLogo} class="logo-div" alt={Logo} width="170" />
              </NavLink>
            </div>
            <div class="nav-2">
              <div class="nav-main">
                {isAuthenticated ? (
                  <span>
                    <button className="btn-3" onClick={handleShowNavbar}>
                      My Account
                    </button>
                  </span>
                ) : (
                  // <Link className="btn-3" to="/LoginRedirectPage">
                  //   Login with BigBonanza
                  // </Link>

                  <button className="btn-3" onClick={handleLoginRedirect}>
                 Login with BigBonanza
                </button>
                )}
                {showNavbar && (
                  <div className="MyAccountNav" ref={navbarRef}>
                    <ul>
                          <li>
                        <Link to="/My-Orders" onClick={handleShowNavbar}>
                          {" "}
                          My Orders
                        </Link>
                      </li> 


                      <li>
                        <Link to="/token-transition" onClick={handleShowNavbar}>
                          {" "}
                          Tockens Transition
                        </Link>
                      </li>

                      <li>
                        <Link to="/" onClick={handleRedirect}>
                          {" "}
                          Logout
                        </Link>
                      </li>

                     
                    </ul>
                  </div>
                )}
                
                {showModal &&
                  createPortal(
                    <ModalContent onClose={() => setShowModal(false)} />,
                    document.body
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

export default Header;
