import React, { useEffect } from 'react';

import { Link, useNavigate, useSearchParams } from "react-router-dom";
const Identity = () => {
  const [searchParams] = useSearchParams();
  console.log("searchParams" ,searchParams)
  const navigate = useNavigate();
  useEffect(() => {
    const token = searchParams.get('token');

    const retoken = searchParams.get('retoken');
    const redirectto = searchParams.get('redirectto');

    // alert("2tockes" , token)
    // console.log("2tockes" , token)
    // alert("2Retocken" , retoken)
    // console.log("2Retocken" , retoken)
    // alert("2redirectto" , redirectto)
    // console.log("2redirectto" , redirectto)
    
    if (token) {

      const user = {
        signup_status: true,
        refresh: retoken,
        access: token,
      };

// Stringify the user object before storing it
localStorage.setItem('user', JSON.stringify(user));

window.location.href = redirectto.toString();

 


  
      // if (next) {
      //   // window.location.href = next."?tocken=dfsdfbsdbfj";

      //   const url = new URL(next);
      // url.searchParams.set('token', 'dfsdfbsdbfj'); // Add or update the token parameter
      
      // // Redirect to the modified URL
      // window.location.href = url.toString();
     // }
    }else{
      window.location.href = "https://tockens.com";
    }
  }, [searchParams]);

  return (
    <div>
      <h1></h1>
    </div>
  );
};

export default Identity;
