import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { baseURL, getSurveyCategories } from "../services/Aut.post.js";

function SurveyCategory({ isAuthenticated }) {
  const [allDeals, setAllDeals] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSurveyCategories();
        setAllDeals(data.categories); // Update with the actual categories array
        console.log(data);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchData();
  }, []);

  const onClickCategories = (value) => {
    localStorage.setItem("selectedShoppingCategoryName", value.title);

    const getsurveytitle = localStorage.getItem("selectedShoppingCategoryName");

    navigate(`/Survey-Categories/Survey-${getsurveytitle}-list/${value._id}`)
  };

//src={`${baseURL}${aboutObj["Contestlogo"]}`}

  return (
    <>
      <div className="top-space-1">
        <div className="extra-offer-wrap">
          <h1>Survey Categories</h1> {/* Page Title */}
          <div className="extra-offer-flex-2-wrap">
            {allDeals.length > 0 ? (
              allDeals.map((value, index) => (
                <div className="extra-offer-2" key={index}>
                  <div className="extra-offer-2-img">
                    {/* <h1>{value.title}</h1> */}
                    {/* <img src = {`${baseURL}/survey_images/${value.image_path}`} alt = {value.title}/> */}
                <img
                 src = {
                    value.image_path ? 
                   `${baseURL}/media/${value.image_path}` : `${value.title}`
                  }
                  alt = {value.title}/>
                  </div>
                  <h6>
                    {/* Title */}
                    <button onClick={() => onClickCategories(value)}>
                      {value.title}
                    </button>
                  </h6>
                </div>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>

      
    </>
  );
}

export default SurveyCategory;
