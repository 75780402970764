const sanitizeHTML = (htmlContent) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    return tempElement.textContent || tempElement.innerText || "";
  };
  
  const EncrptionComponent = ({ content }) => {
    const sanitizedContent = sanitizeHTML(content);
  
    return <p>{sanitizedContent}</p>
  };
  
  export default EncrptionComponent;
  