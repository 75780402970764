import React, { useEffect, useState } from "react";
import { baseURL, getAllResultDetailsThirdarrg } from "../services/Aut.post.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Styles/ExtraOffers.css";

const SurveyDetails = () => {
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const btoken = accessToken.access;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${String(btoken)}`,
  };

  const { id, cname } = useParams();

  const [survey, setSurvey] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [imageUploads, setImageUploads] = useState({});
  const [isFormValid, setIsFormValid] = useState(false); // New state for form validation

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllResultDetailsThirdarrg(id, cname);
        if (data && data.title) {
          setSurvey(data);
        } else {
          console.error("Invalid data format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, cname]);

  useEffect(() => {
    // Validate the form whenever inputs change
    if (survey) {
      const allQuestionsFilled = survey.questions.every((question) => {
        const questionId = question.question_id;

        switch (question.question_type) {
          case "multiple_choice":
          case "radio":
            return !!selectedOptions[questionId]; // Check if option is selected
          case "text":
            return textAnswers[questionId]?.trim() !== ""; // Check if text is filled
          case "image":
            return !!imageUploads[questionId]; // Check if image is uploaded
          default:
            return true;
        }
      });

      setIsFormValid(allQuestionsFilled); // Update form validity
    }
  }, [survey, selectedOptions, textAnswers, imageUploads]);

  const handleOptionChange = (questionId, optionText) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [questionId]: optionText,
    }));
  };

  const handleTextChange = (questionId, text) => {
    setTextAnswers((prevState) => ({
      ...prevState,
      [questionId]: text,
    }));
  };

  const handleImageUpload = (questionId, event) => {
    const file = event.target.files[0];
    setImageUploads((prevState) => ({
      ...prevState,
      [questionId]: file,
    }));
  };

  const renderOptions = (question) => {
    switch (question.question_type) {
      case "multiple_choice":
        return (
          <div className="select-options">
            <select
              id={`dropdown-${question.question_id}`}
              value={selectedOptions[question.question_id] || ""}
              onChange={(e) =>
                handleOptionChange(question.question_id, e.target.value)
              }
            >
              <option value="">Select an option</option>
              {question.options &&
                question.options.map((option, index) => (
                  <option key={index} value={option.option_text}>
                    {option.option_text}
                  </option>
                ))}
            </select>
          </div>
        );
      case "radio":
        return (
          <div className="radio-options">
            {question.options &&
              question.options.map((option, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    id={`radio-${index}-${question.question_id}`}
                    name={question.question_id}
                    value={option.option_text}
                    checked={
                      selectedOptions[question.question_id] === option.option_text
                    }
                    onChange={() =>
                      handleOptionChange(question.question_id, option.option_text)
                    }
                  />
                  <label htmlFor={`radio-${index}-${question.question_id}`}>
                    {option.option_text}
                  </label>
                </div>
              ))}
          </div>
        );
      case "text":
        return (
          <div>
            <input
              className="text-input"
              type="text"
              id={`text-${question.question_id}`}
              value={textAnswers[question.question_id] || ""}
              onChange={(e) =>
                handleTextChange(question.question_id, e.target.value)
              }
            />
          </div>
        );
      case "image":
        return (
          <div>
            <input
              className="file-input"
              type="file"
              id={`image-${question.question_id}`}
              accept="image/*"
              onChange={(e) => handleImageUpload(question.question_id, e)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const responses = {
      selectedOptions,
      textAnswers,
      imageUploads,
    };

    console.log("Survey responses to submit:", responses);


    axios
      .post(`${baseURL}/tockens_api/surveyresponse/${id}/${cname}`, responses, {
        headers: headers,
      })
      .then(() => navigate("/Survey-Confrim"))
      .catch((error) => {
        console.error("Error submitting response:", error);
        alert("Error submitting response");
      });
  };

  return (
    <div className="top-space-1">
      <div className="extra-offer-wrap">
        <h1>Survey's</h1>
        <form onSubmit={handleSubmit}>
          <div>
            {survey ? (
              <div>
                <h2>{survey.title}</h2>
                <p>{survey.description}</p>
                <div className="survey-list-container">
                  {survey.questions.map((question) => (
                    <div
                      key={question.question_id}
                      className="survey_container"
                    >
                      <div className="survey-item-wrap">
                        {question.question_text}
                      </div>
                      {renderOptions(question)}
                    </div>
                  ))}
                  <p className="surevy-p-tag">
                    “ Your Personal data will not be Shared with anyone ”
                  </p>
                </div>
                <button
                  className={isFormValid ? "survey-btn" : "survey-btn disabled"}
                  type="submit"
                  disabled={!isFormValid}
                >
                  Submit
                </button>
              </div>
            ) : (
              <p>Loading survey details...</p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SurveyDetails;
