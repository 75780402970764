import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/ExtraOffers.css";
import amazon from "./images/earphones.png"; 
import { baseURL, getProductsAll } from "../services/Aut.post.js";

function Shopping({ isAuthenticated }) {
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProductsAll();
        setAllProducts(data);
        console.log("Fetched Products:", data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  const onBuyProducts = (product) => {

    // alert(isAuthenticated)
    if (isAuthenticated) {
       navigate(`/Product-details/${product.product_unqcode}`);
     }
     else {
      navigate("/phonesignup");
    }
  };

  return (
    <div className="top-space-1">
      <div className="extra-offer-wrap">
        <h1>Shopping</h1>
        <div className="extra-offer-flex-2-wrap">
          {allProducts.length > 0 ? (
            allProducts.map((product, index) => (

              <div className="extra-offer-2" key={index}>
                <div className="extra-offer-2-img">
                  <img
                    src={
                      product.product_image
                        ? `${baseURL}${product.product_image}`
                        : amazon
                    }
                    alt={product.product_title || "Product"}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = amazon;
                    }}
                  />
                </div>
                <h5>{product.product_title || "Product Name"}</h5>
                <button onClick={() => onBuyProducts(product)}>
                  Product Details
                </button>
              </div>
              
            ))
          ) : (
            <div>Loading products...</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Shopping;
