import React, { useState } from 'react';
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { baseURL } from '../services/Aut.post';

function Survey() {
  const [responses, setResponses] = useState({});
  const btoken = "static_access_token"; // Replace with actual static token if needed

  const surveyData = [
    {
      id: 1,
      text: "What is your favorite programming language?",
      choices: [
        { id: 1, text: "JavaScript" },
        { id: 2, text: "Python" },
        { id: 3, text: "Java" },
        { id: 4, text: "C++" }
      ]
    },
    {
      id: 2,
      text: "How often do you code?",
      choices: [
        { id: 1, text: "Daily" },
        { id: 2, text: "Weekly" },
        { id: 3, text: "Monthly" },
        { id: 4, text: "Rarely" }
      ]
    }
  ];

  const handleRadioChange = (questionId, choiceId) => {
    setResponses({
      ...responses,
      [questionId]: choiceId
    });
  };

  const handleSubmit = async () => {
    const dataToSubmit = Object.keys(responses).map(questionId => ({
      surveyid: "static_sernuq_id",
      question_id: parseInt(questionId),
      choice_id: responses[questionId]
    }));
    console.log(dataToSubmit);

    try {
      // const response = await fetch('http://127.0.0.1:8000/tockens_api/survey-data/', {
        const response = await fetch(`${baseURL}/tockens_api/survey-data/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${String(btoken)}`,
        },
        body: JSON.stringify(dataToSubmit)
      });

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  };

  return (
    <>
      <div className="top-space-1">
        <div className="extra-offer-wrap">
          <h1>Survey</h1>
          {surveyData.map((question) => (
            <div key={question.id} className="survey_container">
              <h4>{question.text}</h4>
              <div className="survey-item-wrap">
                {question.choices.map((choice) => (
                  <div key={choice.id} className="survey-item">
                    <input
                      type="radio"
                      id={`choice-${choice.id}`}
                      value={choice.text}
                      name={`question-${question.id}`}
                      onChange={() => handleRadioChange(question.id, choice.id)}
                    />
                    <label htmlFor={`choice-${choice.id}`} className="option_name">
                      {choice.text}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <button className="button-orange-1" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </>
  );
}

export default Survey;
